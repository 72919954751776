<template>
  <div v-if="paginationStatus" class="pagination-custom">
    <el-button
      class="pagination-button"
      type="primary"
      plain
      @click="showMoreData"
    >显示更多
    </el-button>
  </div>
</template>

<script>
  export default {
    name: "PaginationCustom",
    data () {
      return {}
    },
    props: {
      paginationStatus: {
        type: Boolean,
        default: true
      }
    },
    mounted () {

    },
    methods: {
      //show more list data
      showMoreData () {
        this.$emit('showMoreData')
      }
    }
  }
</script>

<style scoped lang="less">

  .pagination-custom {
    padding: 1rem;
    text-align: center;
    .pagination-button {
      width: 10rem;
    }
  }

</style>
