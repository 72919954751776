<template>
  <div class="content-show">
    <div class="head" :class="{ tabTwo: showIndex === 1, tabThree: showIndex === 2, tabFour: showIndex === 3 }">
      <div v-if="chooseType !== 'self'" class="head-item" :class="{
        tabItemOne: index === 0 && showIndex === 0,
        tabItemTwo: index === 1 && showIndex === 1,
        tabItemThree: index === 2 && showIndex === 2,
      }" @click="handleSuggestList(index)" v-for="(item, index) in headData" :key="index">
        {{ item.Suggest || item.GroupName }}{{ item.Number }}组
      </div>
      <div class="head-item" :class="showAll" @click="handleSuggestList(3)">全部</div>
    </div>
    <div class="content">
      <el-table :data="mainData[showIndex]" empty-text="暂无数据..." :header-cell-style="{
        background: { 0: '#F56C6C', 1: '#f8cc3d', 2: '#51d729', 3: '#4b63ee' }[showIndex],
        'text-align': 'center',
        color: '#fff',
      }" v-loading="loading" element-loading-background="rgba(255,255,255,.9)" element-loading-text="数据加载中..."
        element-loading-spinner="el-icon-loading" @sort-change="sortChange">
        <el-table-column v-if="wishCategory === 0" label="概率" prop="EnrollPercent" align="center" width="80"
          :sortable="chooseType === 'self' ? false : 'custom'">
          <template slot-scope="props">
            {{ props.row.EnrollPercent }}%
          </template>
        </el-table-column>
        <el-table-column v-else :label="estimateScoreYear + '' + '年'">
          <el-table-column :sortable="chooseType === 'self' ? false : 'custom'" prop="ForecastScore" align="center"
            width="80" label="预测分">
            <template slot-scope="props">
              <div class="probability-title">{{ props.row.ForecastScore }}分</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="院校名称" min-width="120">
          <template slot-scope="props">
            <div class="title-first">
              <span style="cursor: pointer;" @click="toDetails(props.row)">
                {{ props.row.SchoolName }}
              </span>
              <el-tag v-if="props.row.CategoryNames" type="danger" size="mini">{{ props.row.CategoryNames }}</el-tag>
            </div>
            <div class="item-lightest">特色：{{ props.row.TagNames }}</div>
            <div class="item-lightest">性质：{{ props.row.SchoolNature }}</div>
            <div v-if="props.row.Ranking">
              <el-tag size="small">{{ props.row.Ranking + '[' + props.row.RankingType + ']' }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="地区" prop="CityName" align="center" width="120"></el-table-column>
        <el-table-column label="计划">
          <el-table-column prop="PlanPersons" align="center" :label="tableHeadYear.PlanYear + ''" width="120" />
        </el-table-column>
        <el-table-column label="历年录取">
          <el-table-column :label="tableHeadYear.Year1 + ''" width="140"
            :sortable="chooseType === 'self' ? false : 'custom'" prop="enrollPastYear">
            <template slot="header" slot-scope="props">
              {{ tableHeadYear.Year1 }}
              <span class="enrollPastYear-tips">
                <el-popover placement="bottom" title="" width="200" trigger="hover" content="按照院校提档线排序">
                  <img slot="reference" class="question sort-question" src="@/assets/wish/question.png" />
                </el-popover>
              </span>
            </template>
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{
                    computedShowData(props.row.ScoreLowOfYear1)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{
                    computedShowData(props.row.RankLowOfYear1)
                  }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover placement="bottom" title="等效分：" width="200" trigger="hover"
                      :content="'等同于' + BatchYear + '年分数'">
                      <img slot="reference" class="question" src="@/assets/wish/question.png" /> </el-popover>:</span>
                  <span>{{
                    computedShowData(props.row.ScoreEqualOfYear1)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{
                    computedShowData(props.row.ScoreDiffOfYear1)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{
                    computedShowData(props.row.PersonsOfYear1)
                  }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column :label="tableHeadYear.Year2 + ''" width="140" prop="num2018">
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{
                    computedShowData(props.row.ScoreLowOfYear2)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{
                    computedShowData(props.row.RankLowOfYear2)
                  }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover placement="bottom" title="等效分：" width="200" trigger="hover"
                      :content="'等同于' + BatchYear + '年分数'">
                      <img slot="reference" class="question" src="@/assets/wish/question.png" /> </el-popover>:</span>
                  <span>{{
                    computedShowData(props.row.ScoreEqualOfYear2)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{
                    computedShowData(props.row.ScoreDiffOfYear2)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{
                    computedShowData(props.row.PersonsOfYear2)
                  }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column :label="tableHeadYear.Year3 + ''" width="140" prop="num2017">
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{
                    computedShowData(props.row.ScoreLowOfYear3)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{
                    computedShowData(props.row.RankLowOfYear3)
                  }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover placement="bottom" title="等效分：" width="200" trigger="hover"
                      :content="'等同于' + BatchYear + '年分数'">
                      <img slot="reference" class="question" src="@/assets/wish/question.png" /> </el-popover>:</span>
                  <span>{{
                    computedShowData(props.row.ScoreEqualOfYear3)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{
                    computedShowData(props.row.ScoreDiffOfYear3)
                  }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{
                    computedShowData(props.row.PersonsOfYear3)
                  }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="专业" align="center" width="120">
          <template slot-scope="props">
            <el-button class="specialty-button" @click="showSpecialty(props.row)" type="primary" plain size="mini">专业{{ props.row.NumberOfSpecialty }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="props">
            <el-button @click="selectWish(props.row)" size="mini" :type="buttonStatus(props.row)">{{ fillStatus(props.row)
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="authorization">
      <!-- 分页 开始 -->
      <pagination-custom :paginationStatus="paginationStatus[showIndex]" @showMoreData="showMoreData"></pagination-custom>
      <!-- 分页 结束 -->
    </div>
    <div v-else>
      <member-limit :text_1="wishCategory===0?'开通':'升级'" :text_2="wishCategory===0?nameConfig.WCF:nameConfig.WDF"></member-limit>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";
import { getStore } from '@/utils/utils';
import PaginationCustom from "./PaginationCustom";
import MemberLimit from './MemberLimit.vue';

export default {
  name: "ContentShow",
  components: {
    PaginationCustom,
    MemberLimit
  },
  inject: ['getScrollTop', 'setScrollTop'],
  data() {
    return {
      hasZNK: this.$store.state.user.hasZNK,
      hasVip: this.$store.state.user.hasVip,

      headData: [],
      mainData: [],
      showIndex: 0,
      tableHeadYear: "",
      BatchYear: "",
      estimateScoreYear: '',
      wishGradeList: "ABCDEFGHIJKLMNO",
      loading: false,
      wishCategory: 0,
      query: {},
      PageIndexList: [1, 1, 1, 1],
      specialtyFillStatus: false,
      paginationStatus: [true, true, true, true],

      // wishLimitNumber: {},
      allowBeyondWishNumber: false,

      login: getStore('LoginData') || '',

      authorization: true, // 权限

    };
  },
  props: {
    contentApi: {
      type: Object,
      default: () => ({}),
    },
    chooseType: {
      type: String,
      default: "",
    },
    totalData: {
      type: Array,
      default: () => [],
    },
    schoolMainData: {
      type: Array,
      default: () => [],
    },
    wishLimitNumber: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters(["nameConfig"]),
    showAll() {
      return this.showIndex === 3 ? 'tabItemAll' : ''
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    //select grade for wish college
    selectWish(row) {
      if (this.login) {
        let tempRow = this.totalData.find((item, index) => {
          if (item.SchoolName === row.SchoolName) {
            this.$delete(item, 'selectedSpecialty')
            this.$delete(item, 'beyondSpecialtyLimitNumber')
            this.totalData.$removeOfProperty('SchoolName', row.SchoolName)
            return true
          }
        })
        if (!tempRow) {

          // 是否可以超过省份标准数量限制
          if (this.allowBeyondWishNumber) {
            this.fillWishData(row)
          } else {
            // 判断已填志愿是否超过省份标准数量
            if (this.totalData.length >= this.wishLimitNumber.WishCount) {
              this.$confirmTips('您填报的志愿数量已超过' + this.wishLimitNumber.WishCount + '个，是否继续填报？', '注意！', 'warning').then(_ => {
                this.fillWishData(row)
                this.allowBeyondWishNumber = true
              }).catch(_ => {
                this.allowBeyondWishNumber = false
              })
            } else {
              this.fillWishData(row)
            }
          }
        }
      } else {
        this.$router.push('/login')
      }
    },
    // 处理数据添加
    fillWishData(row) {
      this.$set(row, 'IsTransfer', true)
      this.totalData.push(row)
      this.showSpecialty(row)

      this.$ls.set('selectedOldWish', [true])
    },

    // 列表中志愿填报状态
    fillStatus(row) {
      let tempFlag = this.totalData.some(item => {
        return item.SchoolName === row.SchoolName
      })
      return tempFlag ? '已填' : '填报'
    },
    // 填报按钮状态
    buttonStatus(row) {
      let tempFlag = this.totalData.some(item => {
        return item.SchoolName === row.SchoolName
      })
      return tempFlag ? 'primary' : ''
    },

    //click specialty button to show specialty list
    showSpecialty(row) {
      this.$emit('showSpecialty', row)
    },
    //sort list
    sortChange(data) {
      const order = data.order;
      this.query.Sort = {}
      if (data.prop === "enrollPastYear") {
        if (order === "ascending") {
          this.query.Sort.ScoreLowOfYear1 = 'asc';
        } else if (order === "descending") {
          this.query.Sort.ScoreLowOfYear1 = 'desc';
        } else {
          // this.$delete(this.query, 'Sort')
          this.query.Sort = {}
        }
      } else {
        if (order === "ascending") {
          this.wishCategory === 1 && (this.query.Sort.ForecastScore = 'asc');
          this.wishCategory === 0 && (this.query.Sort.ScoreEqualOfTotal = 'desc')
        } else if (order === "descending") {
          this.wishCategory === 1 && (this.query.Sort.ForecastScore = 'desc');
          this.wishCategory === 0 && (this.query.Sort.ScoreEqualOfTotal = 'asc')
        } else {
          if (this.wishCategory === 1) {
            this.query.Sort.ForecastScore = 'desc';
          } else {
            this.$delete(this.query, "Sort");
          }
        }
      }
      this.query.PageIndex = 1;
      this.getListData();
    },
    //suggest group change
    handleSuggestList(index) {

      if (index !== 0) {

        let temp = ( this.wishCategory === 0?'WCFTB':'WDFTB')
        
        this.$store.dispatch('user/allowMember', [temp]).then(flag => {
          if (flag) {

            this.showIndex = index;
            if (!this.mainData[index] || this.mainData[index].length === 0) {
              this.query.PageIndex = 1;
              this.getListData();
            }
          } else {
            let tempText = this.wishCategory === 0?('此功能需要开通'+this.nameConfig.WCF):('此功能需要升级'+this.nameConfig.WDF)

            this.$confirm(tempText, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {

              let temp = this.wishCategory === 0?'WCF':'WDF'
              this.$router.push('/openvip?productCode='+temp)
            }).catch(() => { });
          }
        })
      } else {

        this.showIndex = index;
        if (!this.mainData[index] || this.mainData[index].length === 0) {
          this.query.PageIndex = 1;
          this.getListData();
        }
      }
    },
    //open college details
    toDetails(row) {
      const routeUrl = this.$router.resolve({
        path: '/university-detail',
        query: { id: row.SchoolId }
      })
      window.open(routeUrl.href, '_blank')
    },
    //initialize data
    initialize() {
      this.getUserParameters();
      this.getEstimateScoreYear()



    },
    //merge query
    mergeParameters(query) {
      this.query = Object.assign(this.query, query);
      if (this.chooseType === "specialty") {
        this.getStatData();
      } else {
        this.getListData();
      }
    },
    //get storage data
    getUserParameters() {
      this.$getStorage("oldWish").then((res) => {
        this.query = {
          ProvinceId: res.ProvinceId,
          SubjectType: res.SubjectType,
          BatchId: res.BatchId,
          Score: res.Score,
          ScoreEqual: res.ScoreEqual,
          PageSize: 10,
          ScoreLowerLimit: PLATFROM_CONFIG.ScoreLowerLimit || -30,
          ScoreUpperLimit: PLATFROM_CONFIG.ScoreUpperLimit || 15,
          SchoolTagList: [],
          SchoolCategoryIdList: [],
          SchoolProvinceIdList: [],


          Sort: {}
        };
        this.wishCategory = res.wishCategory;
        this.tableHeadYear = res.tableHeadYear;
        this.BatchYear = res.tableHeadYear.Year1;

        if (this.chooseType === "school") {
          this.getStatData(); // 获取列表数据
        }

        let temp = ( res.wishCategory === 0?'WCFTB':'WDFTB')

        // 权限
        this.$store.dispatch('user/allowMember', [temp]).then(flag => {
          this.authorization = flag

        })

      });
    },
    getEstimateScoreYear() {
      this.$store.dispatch('tool/getSystemYear').then(year => {
        this.estimateScoreYear = year
      })
      // API.Wish.WishYear().then(res => {
      //   this.estimateScoreYear = res.data.Year
      // })
    },
    //stat data
    getStatData() {
      this.query.PageIndex = 1;
      this.PageIndexList = [1, 1, 1, 1];
      this.mainData = [];
      this.paginationStatus = [true, true, true, true];
      if (this.wishCategory === 1) {
        this.query.PercentSort = 1;
      }
      this.contentApi.statistics(this.query).then((res) => {
        this.headData = res.data;
        this.getListData();
      });
    },
    //list content
    getListData() {
      if (this.chooseType === "self") {
        //myself choose school
        this.paginationStatus[this.showIndex] = false;
        this.contentApi.list(this.query).then((res) => {
          this.mainData[this.showIndex] = this.mainData[this.showIndex] || [];
          let flag = this.totalData.some((item) => {
            if (res.data.ID === item.ID) {
              if (this.mainData[this.showIndex].length === 0) {
                this.$set(this.mainData, this.showIndex, [item]);
              } else {
                this.mainData[this.showIndex].push(item);
              }
              return true;
            }
          });
          if (!flag) {
            if (this.mainData[this.showIndex].length === 0) {
              this.$set(this.mainData, this.showIndex, [res.data]);
            } else {
              this.mainData[this.showIndex].push(res.data);
            }
          }
          // this.$emit("getSelfMainData", this.mainData);
        });
      } else {
        //other choose type
        if (this.showIndex === 3) {
          this.$delete(this.query, 'PercentBegin')
          this.$delete(this.query, 'PercentEnd')
        } else {
          this.query.PercentBegin =
            this.headData[this.showIndex].PercentBegin ||
            this.headData[this.showIndex].ScoreBegin;
          this.query.PercentEnd =
            this.headData[this.showIndex].PercentEnd ||
            this.headData[this.showIndex].ScoreEnd;
        }
        this.loading = true;
        let height = this.getScrollTop();
        this.contentApi.list(this.query).then((res) => {
          res.data.Data.forEach((item, index) => {
            this.totalData.forEach((subItem) => {
              if (item.ID === subItem.ID) {
                this.$set(res.data.Data, index, subItem);
              }
            });
          });
          if (this.query.PageIndex >= res.data.TotalPage) {
            this.paginationStatus[this.showIndex] = false;
          } else {
            this.paginationStatus[this.showIndex] = true;
          }
          if (this.query.PageIndex === 1) {
            this.$set(this.mainData, this.showIndex, res.data.Data);
          } else {
            this.mainData[this.showIndex] = this.mainData[this.showIndex] || [];
            this.mainData[this.showIndex] = this.mainData[
              this.showIndex
            ].concat(res.data.Data);
          }
          setTimeout(() => {
            this.setScrollTop(height);
          }, 0);
          this.loading = false;
        });
      }
    },
    //get list by screen parameters
    getListByScreen(screenParameters) {
      let temp = {
        SchoolProvinceIdList: screenParameters.provinceID,
        SchoolCategoryIdList: screenParameters.categoryID,
        SchoolTagList: screenParameters.tagValue,
        SchoolNature: screenParameters.natureValue || null,
        ScoreLowerLimit: screenParameters.ScoreLowerLimit,
        ScoreUpperLimit: screenParameters.ScoreUpperLimit,
      };
      Object.assign(this.query, temp);
      this.getStatData();
    },
    //pagination and show more data
    showMoreData() {
      this.PageIndexList[this.showIndex]++;
      this.query.PageIndex = this.PageIndexList[this.showIndex];
      this.getListData();
    },
    // 展示数据
    computedShowData(item) {
      return item || item === 0 ? item : '-'
    }
  },
};
</script>

<style scoped lang="less">
.content-show {
  .head {
    padding: 0 1.5rem;
    border-bottom: 2px solid var(--color-red);
    display: flex;

    &-item {
      margin-right: 2rem;
      padding: 1rem 0;
      letter-spacing: 0.3rem;
      font-size: 20px;
      color: var(--text-font-color);
      cursor: pointer;
      user-select: none;
    }

    .tabItemOne {
      color: var(--color-red);
    }

    .tabItemTwo {
      color: #f8cc3d;
    }

    .tabItemThree {
      color: #51d629;
    }

    .tabItemAll {
      color: #4b63ee;
    }
  }

  .tabTwo {
    border-bottom: 2px solid #f8cc3d;
  }

  .tabThree {
    border-bottom: 2px solid #51d629;
  }

  .tabFour {
    border-bottom: 2px solid #4b63ee;
  }

  .content {
    margin-top: 0.5rem;

    .title-first {
      font-size: 18px;
      color: var(--text-font-color);
    }

    .item-lightest {
      font-size: 13px;
      color: var(--text-font-color);
    }

    .emphasize {
      color: var(--color-red);
    }

    .min-grade {
      font-size: 12px;
    }

    .question {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
    }

    .sort-question {
      background-color: #ffffff;
      border-radius: 50%;
    }

    .enrollPastYear-tips {
      position: absolute;
      top: 50%;
      right: calc(50% - 45px);
      transform: translateY(-53%);
    }

    .specialty-button {
      min-width: 88px;
    }
  }
}

.el-dropdown-menu {
  max-height: 20rem;
  min-width: 6.5rem;
  overflow: auto;
}

>.el-loading-mask {
  z-index: 1999;
}
</style>
<style lang="less">
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>