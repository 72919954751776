<template>
  <div class="specialty-first-search">
    <el-autocomplete
      class="search-input"
      v-model="searchQuery"
      @select="handleSearchSelect"
      :value-key="valueKey"
      :fetch-suggestions="querySearch"
      :placeholder="placeHolder"
    >
      <template slot-scope="{ item }">
        <span class="name">{{item[valueKey]}}</span>
        <el-tag v-if="tagValue" type="primary" size="mini">{{item[tagValue]}}</el-tag>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
  export default {
    name: "SpecialtyFirstSearch",
    props:{
      placeHolder:{
        type: String,
        default: '请输入...'
      },
      valueKey:{
        type: String,
        default: ''
      },
      tagValue:{
        type: String,
        default: ''
      }
    },
    data(){
      return{
        searchQuery: '',
      }
    },
    mounted(){},
    methods:{
      //handle select
      handleSearchSelect(query){
        this.$emit('handleSearchSelect', query)
      },
      // search suggestion
      querySearch(query, callback){
        if(!query) return
        this.$emit('searchData', query, res => {
          callback(res)
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .specialty-first-search{
    padding: 1rem 0;
    .search-input{
      width: 100%;
      .name{}
    }
  }

</style>
