<template>
  <div class="specialty-list">
    <el-dialog
      :title="schoolName"
      :visible.sync="specialtyListStatus"
      append-to-body
      align="center"
      top="5vh"
      width="80%"
      close-on-click-modal
      @close="closeSpecialtyList"
    >
      <el-table
        header-align="center"
        :data="mainData"
        :header-cell-style="{background: 'rgba(var(--themecolor),0.75)','text-align': 'center',color: '#fff'}"
        v-loading = 'specialtyListLoading'
        element-loading-background="rgba(255,255,255,.9)"
        element-loading-text="数据加载中..."
        element-loading-spinner="el-icon-loading"
        @sort-change="sortChange"
      >
        <el-table-column v-if="wishCategory=== 0" label="专业概率" align="center" width="80">
          <template slot-scope="props">
            {{ props.row.EnrollPercent }}%
          </template>
        </el-table-column>
        <el-table-column v-else align="center" width="80" label="预测分">
          <template slot-scope="props">
            <div>{{ props.row.ForecastScore }}分</div>
          </template>
        </el-table-column>
        <el-table-column label="招生专业">
          <template slot-scope="props">
            <div>
              <span style="cursor: pointer;" @click="toSpecialityDetails(props.row)">{{ props.row.SpecialtyName }}</span>
              <div>
                <el-tag size="small" v-if="props.row.LearnSubjectAssessment">学科评估：{{ props.row.LearnSubjectAssessment }}</el-tag>
                <el-tag size="small" v-if="props.row.SpecialtyRank">专业排名：{{ props.row.SpecialtyRank }}</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column align="center" width="60" label="学科评估" prop="LearnSubjectAssessment"></el-table-column>-->
<!--        <el-table-column align="center" width="60" label="专业排名" prop="SpecialtyRank"></el-table-column>-->
        <el-table-column label="计划">
          <el-table-column
            align="center"
            width="100"
            :label="tableHeadYear.PlanYear+''"
          >
            <template slot-scope="props">
              <div v-if="props.row.PlanPersons" class="learning-cost">
                <div>{{ props.row.PlanPersons }}人</div>
                <div v-if="props.row.LearningCost > 0">{{ props.row.LearningYears+'年'+'￥'+ props.row.LearningCost }}/年</div>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="历年录取"
          min-width="210"
        >
          <el-table-column
            :label="tableHeadYear.Year1+''"
            width="140"
            sortable="custom"
            prop="specialtyPastYear"
          >
            <template slot="header">
              {{ tableHeadYear.Year1 }}
              <span class="specialtyPastYear-tips">
                <el-popover
                  placement="bottom"
                  title=""
                  width="200"
                  trigger="hover"
                  content="按照专业提档线排序">
                <img
                  slot="reference"
                  class="question sort-question"
                  src="@/assets/wish/question.png"
                />
              </el-popover>
              </span>
            </template>
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{ computedShowData(props.row.ScoreLowOfYear1) }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{ computedShowData(props.row.RankLowOfYear1) }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover
                    placement="bottom"
                    title="等效分："
                    width="200"
                    trigger="hover"
                    :content="'等同于'+BatchYear+'年分数'"
                  >
                    <img slot="reference" class="question" src="@/assets/wish/question.png">
                  </el-popover>:</span>
                  <span>{{ computedShowData(props.row.ScoreEqualOfYear1) }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{ computedShowData(props.row.ScoreDiffOfYear1) }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{ computedShowData(props.row.PersonsOfYear1) }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column :label="tableHeadYear.Year2+''" width="140">
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{ computedShowData(props.row.ScoreLowOfYear2) }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{ computedShowData(props.row.RankLowOfYear2) }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover
                    placement="bottom"
                    title="等效分："
                    width="200"
                    trigger="hover"
                    :content="'等同于'+BatchYear+'年分数'"
                  >
                    <img slot="reference" class="question" src="@/assets/wish/question.png">
                  </el-popover>:</span>
                  <span>{{ computedShowData(props.row.ScoreEqualOfYear2) }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{ computedShowData(props.row.ScoreDiffOfYear2) }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{ computedShowData(props.row.PersonsOfYear2) }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column :label="tableHeadYear.Year3+''" width="140">
            <template slot-scope="props">
              <ul>
                <li>
                  <span class="item-lightest">最低分:</span>
                  <span>{{ computedShowData(props.row.ScoreLowOfYear3) }}</span>
                </li>
                <li>
                  <span class="item-lightest">最低位次:</span>
                  <span class="min-grade">{{ computedShowData(props.row.RankLowOfYear3) }}</span>
                </li>
                <li>
                  <span class="emphasize">等效分<el-popover
                    placement="bottom"
                    title="等效分："
                    width="200"
                    trigger="hover"
                    :content="'等同于'+BatchYear+'年分数'"
                  >
                    <img slot="reference" class="question" src="@/assets/wish/question.png">
                  </el-popover>:</span>
                  <span>{{ computedShowData(props.row.ScoreEqualOfYear3) }}</span>
                </li>
                <li>
                  <span class="item-lightest">线差:</span>
                  <span>{{ computedShowData(props.row.ScoreDiffOfYear3) }}</span>
                </li>
                <li>
                  <span class="item-lightest">人数:</span>
                  <span>{{ computedShowData(props.row.PersonsOfYear3) }}</span>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="填报" align="center" width="90">
          <template slot-scope="props">
            <!--对象的属性有值存在，这里表现为true，没有值或者值为0则为false-->
            <el-button
              style="width: 60px; padding: 6px; text-align: center"
              @click="selectSpecialty(props.row)"
              :type="props.row.specialtySelectStatus?'danger':'primary'"
              size="mini"
              plain>{{props.row.specialtySelectStatus?'已填报':'填报'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {SpecialtyList} from '@/api/common'
  // import {SpecialtyListForecast} from '@/api/WDFapi/common'

  export default {
    name: "SpecialtyShow",
    data () {
      return {
        mainData: [],
        specialtyListStatus: false,
        specialtyListLoading: true,
        wishCategory: 0,
        contentApi: API.Wish.SpecialtyList,
        schoolName: '',
        tableHeadYear: '',
        BatchYear: '',
        query: {},

        selectedSpecialtyData: [],
        // 专业列表对应的院校对象
        itemCollege: {}
      }
    },
    props:{
      specialtyFillStatus:{
        type: Boolean,
        default: false
      },
      specialtyLimitNumber:''
    },
    /*watch:{
      specialtyLimitNumber(){}
    },*/
    created(){
      this.initialize()
    },
    mounted () {},
    methods: {
      //fill in specialty
      selectSpecialty(row){
        if(this.specialtyFillStatus){
          this.mainData.forEach(item => {
            if(item.ID === row.ID){
              if(item.specialtySelectStatus === true){ // 已经选该专业
                this.$set(item, 'specialtySelectStatus', false)
                this.selectedSpecialtyData = this.selectedSpecialtyData.$remove(row, 'ID')
              }else{ // 未选该专业
                // 判断已选数量是否到达上线
                if(this.selectedSpecialtyData.length >= this.specialtyLimitNumber*1){ // 已达上限
                  if(this.itemCollege.beyondSpecialtyLimitNumber){ // 用户解除了限制
                    this.addSpecialtyData(item)
                  }else{ // 未解出限制
                    this.$confirmTips('您填报的专业数量已超过'+this.specialtyLimitNumber+'个，是否继续添加？', '注意', 'warning').then(_=>{
                      this.addSpecialtyData(item)
                      this.$set(this.itemCollege, 'beyondSpecialtyLimitNumber', true)
                    }).catch(_=>{
                      this.$set(this.itemCollege, 'beyondSpecialtyLimitNumber', false)
                    })
                  }
                }else{ // 未达上限
                  this.addSpecialtyData(item)
                }
              }
            }
          })
        }else{
          this.$messageTips('info', '请先将该学校选为志愿')
        }
      },
      // 添加数据
      addSpecialtyData(item){
        this.$set(item, 'specialtySelectStatus', true)
        this.selectedSpecialtyData.push(item)
      },

      //close list
      closeSpecialtyList(){
        if(this.selectedSpecialtyData.length === 0) return
        this.$emit('specialtyData', this.selectedSpecialtyData, this.query.SchoolLineId)
        this.selectedSpecialtyData = []
      },
      //initialize data
      initialize () {
        this.getStorageParameters()
      },
      // 排序
      sortChange(data){
        const order = data.order
        this.query.Sort = {}
        if(data.prop === 'specialtyPastYear'){
          if(order === 'ascending'){
            this.query.Sort.ScoreLowOfYear1 = 'asc'
          }else if (order === 'descending'){
            this.query.Sort.ScoreLowOfYear1 = 'desc'
          }else{
            // this.$delete(this.query, 'Sort')
            this.query.Sort = {}
          }
        }
        this.getSpecialtyFinalList()
      },
      //get storage parameters
      getStorageParameters () {
        this.$getStorage('oldWish').then(res => {
          this.query = {
            ProvinceId: res.ProvinceId,
            Score: res.Score,
            ScoreEqual: res.ScoreEqual,
            SubjectType: res.SubjectType,
             Sort: {}
          }
          this.tableHeadYear = res.tableHeadYear
          this.BatchYear = res.tableHeadYear.Year1
          this.wishCategory = res.wishCategory
          if(res.wishCategory === 0){
            this.contentApi = API.Wish.SpecialtyList
          }else{
            this.contentApi = API.Wish.SpecialtyListForecast
          }
        })
      },
      //get specialty data
      getSpecialtyListData (row) {
        this.itemCollege = row
        this.selectedSpecialtyData = row.selectedSpecialty || []
        this.specialtyListStatus = true
        this.schoolName = row.SchoolName
        this.query.SchoolLineId = row.ID
        this.getSpecialtyFinalList()
      },
      // 获取数据列表
      getSpecialtyFinalList(){
        this.specialtyListLoading = true
        this.contentApi(this.query).then(res => {
          this.mainData = res.data
          this.specialtyListLoading = false
          this.mainData.forEach(item => {
            this.selectedSpecialtyData.some(subItem => {
              if(item.ID === subItem.ID){
                this.$set(item, 'specialtySelectStatus', true)
                return true
              }
            })
          })
        })
      },
      //open specialty details
      toSpecialityDetails (query) {
        let routerUrl
        if (query.SpecialtyCode.length === 4) {
          routerUrl = this.$router.resolve({
            path: '/specialty-list',
            query: {CategoryCode: query.SpecialtyCode, CategoryName: query.EnrollmentSpecialty}
          })
        } else if (query.SpecialtyCode.length === 6) {
          routerUrl = this.$router.resolve({
            path: '/specialty-detail',
            query: {SpecialtyCode: query.SpecialtyCode}
          })
        }
        window.open(routerUrl.href, '_blank')
        // this.specialtyListStatus = false
      },
      // 展示数据
      computedShowData(item) {
        return item || item === 0 ? item : '-'
      }
    }
  }
</script>

<style scoped lang="less">

  .specialty-list{
   
  }
  .item-lightest {
      font-size: 13px;
      color: var(--text-font-color);
    }
    .emphasize {
      color: var(--color-red);
    }
    .min-grade{
      font-size: 12px;
    }
    .question {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
    }
    .sort-question{
      background-color: #ffffff;
      border-radius: 50%;
    }
    .specialtyPastYear-tips{
      position: absolute;
      top: 50%;
      right: calc(50% - 45px);
      transform: translateY(-55%);
    }
    ::v-deep .el-table .descending .sort-caret.descending{
      border-top-color: var(--color-red);
    }
    ::v-deep .el-table .ascending .sort-caret.ascending{
      border-bottom-color: var(--color-red);
    }

    .learning-cost {
      font-size: 13px;
      color: #666;
    }
    .el-dialog .el-button:first-child {
      margin-left: 0;
    }
</style>
