<template>
  <div class="user-info">
    <!-- <div style="margin-right:20px"><el-button
        @click="goBack"
        type="primary"
        size="small"
        icon="el-icon-arrow-left"
        >返回方案列表</el-button
      ></div> -->
    <div class="left">
      <div class="left-title">位{{mainData.wishCategory === 0?'次':'点'}}法填报系统</div>
    </div>
    <div class="center">
      <div class="center-item">
        <span>省份：</span><span>{{mainData.ProvinceName}}</span>
      </div>
      <div class="center-item">
        <span>成绩：</span><span>{{mainData.Score}}</span>
      </div>
      <div class="center-item">
        <span>{{mainData.SubjectType === 1 ?'文科':'理科'}}</span>
      </div>
      <div class="center-item">
        <span>等效分<el-popover
          v-if="mainData.tableHeadYear"
          placement="bottom"
          title="等效分："
          width="200"
          trigger="hover"
          :content="'等同于'+mainData.tableHeadYear.Year1+'年分数'"
        ><img slot="reference" class="question" src="@/assets/wish/question.png">
          </el-popover>:
          <span class="center-item-score">{{ mainData.ScoreEqual || '-' }}</span>
        </span>
      </div>
      <div class="center-item">
        <span>位次：</span><span>{{mainData.Rank}}</span>
      </div>
      <div class="center-item">
        <span>线差：</span><span>{{mainData.lineDifference}}</span>
      </div>
      <div class="center-item">
        <span>{{mainData.BatchName}}</span>
      </div>
    </div>
    <div class="right">
      <el-button @click="reSelectBatch" type="primary" size="mini">重选批次</el-button>
    </div>
    <batch-dialog
      ref="selectBatch"
      @selectInfo="selectInfo"
      :wishCategory="mainData.wishCategory"
      :Score="mainData.Score*1"
      :SubjectType="mainData.SubjectType"
      :ProvinceId="mainData.ProvinceId"
      :ProvinceName="mainData.ProvinceName"
    ></batch-dialog>
  </div>
</template>

<script>
  import BatchDialog from './BatchDialog'
  export default {
    name: "HeadUserInfo",
    components:{
      BatchDialog,
    },
    data(){
      return{
        mainData: {}
      }
    },
    props: {
      totalData: {
        type: Array,
        default: () => []
      }
    },
    created(){
      this.initialize()
    },
    mounted(){},
    methods:{
      
      //selected info
      selectInfo(info){
        this.mainData = info
        this.$emit("changeBatch");
      },
      //select batch list
      reSelectBatch(){
        
        if(this.totalData.length > 0) {
         this.$confirm('重选批次将清空已填志愿，是否重选？', '注意！', {
           type: 'warning',
         }).then(_ => {
           this.$refs.selectBatch.show()
         }).catch(()=>{})
        }else{
          this.$refs.selectBatch.show()
        }
      },
      //initialize data
      initialize(){
        this.$getStorage('oldWish').then(res => {
          this.mainData = res
        })
      }
    }
  }
</script>

<style scoped lang="less">

  .user-info {
    display: flex;
    align-items: center;
    .left {
      margin-right: 3rem;
      &-title {
        font-size: 20px;
        color: rgba(var(--themecolor),1);
      }
    }
    .center {
      width: 36rem;
      display: flex;
      align-items: center;
      color: #999;
      font-size: 14px;
      &-item {
        margin-right: 1rem;
        &-score{
          position: relative;
          top: 1.5px;
        }
      }
    }
    .right{}
    .question {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
    }
  }

</style>
<style lang="less">
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
</style>