<template>
  <div class="app-container choose-wish">
    <!-- 志愿填报 开始 -->
    <wish-index ref="wishMain" v-on="$listeners" v-bind="$attrs"></wish-index>
    <!-- 志愿填报 结束 -->
  </div>
</template>

<script>
import WishIndex from '@/views/choose-wish/local-components/WishIndex'
export default {
  name: "Choose-Wish-Index",
  components: {
    WishIndex,
  },
  data() {
    return {
      mainData: {}
    }
  },
  provide() {
    return {
      getScrollTop: this.getScrollTop,
      setScrollTop: this.setScrollTop,
    }
  },
  mounted() {
    window.addEventListener("beforeunload", () => { // 刷新时，执行
      this.$ls.remove("selectedOldWish");
    });

    this.initialize()
  },
  beforeRouteLeave(to, from, next) {
    const tempList = this.$ls.get("selectedOldWish");
    if (tempList && tempList.length > 0) {
      const flag = confirm("离开该页面将删除缓存的志愿数据, 是否继续?", "提示");
      if (flag) {
        this.$ls.remove("selectedOldWish");
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    initialize() {
      this.$getStorage('oldWish').then(res => {
        this.mainData = res
      })
    },

    getScrollTop() {
      return document.documentElement.scrollTop
    },
    setScrollTop(val) {
      document.documentElement.scrollTop = val
    },
  }
}
</script>

<style scoped lang="less">
.choose-wish {
  box-sizing: border-box;
  height: 100%;
  min-height: 80vh;
  overflow: auto;
  background-color: #fff;
}
</style>
