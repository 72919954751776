<template>
  <div class="school-first">
    <div class="head-box">
      <condition-screen @submitParameters="submitParameters"></condition-screen>
    </div>
    <div class="content-box">
      <content-show
        ref="contentShow"
        chooseType="school"
        :contentApi="contentApi"
        v-bind="$attrs"
        v-on="$listeners"
      ></content-show>
    </div>
  </div>
</template>

<script>
  // import {
  //   SchoolFirstStat,
  //   SchoolFirstList,
  // } from '@/api/common'
  // import {
  //   SchoolFirstStatForecast,
  //   SchoolFirstListForecast
  // } from '@/api/WDFapi/common'
  import API from '@/api/config'
  import ConditionScreen from './ConditionScreen'
  import ContentShow from './ContentShow'
  export default {
    name: "SchoolFirst",
    components:{
      ConditionScreen,
      ContentShow,
    },
    data(){
      return{
        contentApi:{}
      }
    },
    created(){
      this.initialize()
    },
    mounted(){},
    methods:{
      submitParameters(query){
        this.$refs.contentShow.getListByScreen(query)
      },
      //initialize data
      initialize(){
        this.judgeWishCategory()
      },
      //get user parameters and judge wish category
      judgeWishCategory(){
        this.$getStorage('oldWish').then(res => {
          if(res.wishCategory === 0){
            this.contentApi= {
              statistics: API.Wish.SchoolFirstStat,
              list: API.Wish.SchoolFirstList
            }
          }else{
            this.contentApi= {
              statistics: API.Wish.SchoolFirstStatForecast,
              list: API.Wish.SchoolFirstListForecast
            }
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .school-first{
    .head-box{
      padding-left: 20px;
      margin-top: 20px;
    }
    .content-box{}
  }
</style>
