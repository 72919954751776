<template>
  <div v-if="mainData.length !== 0" class="adjust-wish">
    <div class="main">
      <div class="left" @click="openAdjustWish">查看志愿表</div>
      <div class="right" :class="{ 'wish-table': wishTableStatus }">
        <el-scrollbar
          class="box"
          v-loading="loadingStatus"
          element-loading-background="rgba(255,255,255,.9)"
          element-loading-text="数据加载中..."
          element-loading-spinner="el-icon-loading"
        >
          <div v-if="mainData.length !== 0" class="content">
            <div id="content_box">
              <div
                class="content-list"
                v-for="(item, index) in mainData"
                :key="item.ID"
              >
                <div class="content-list-head">
                  <div class="content-list-head-first">
                    <div class="wish-grade">{{ wishStandardOrder.charAt(index) }}志愿</div>
                    <div class="drag-tips">此区域可拖拽排序</div>
                  </div>
                  <div class="content-list-head-second">
                    <el-button class="delete-button" @click="deleteWishCollege(item)" type="danger" size="mini">删除</el-button>
                  </div>
                </div>
                <div class="content-list-content">
                  <div class="content-list-content-left">
                    <div class="base-item">
                      <span>{{ item.SchoolCode }}</span>
                      <span class="school-name">{{ item.SchoolName }}</span>
                    </div>
                    <div v-if="item.Ranking">
                      <el-tag size="mini">{{ item.Ranking + '['+item.RankingType+']' }}</el-tag>
                    </div>
                    <div v-if="userParameters.tableHeadYear" class="base-item">
                      {{ userParameters.tableHeadYear.PlanYear }}计划：{{
                        item.PlanPersons
                      }}人
                    </div>
                    <div class="base-item">
                      <div>服从调剂：</div>
                      <el-radio-group size="mini" v-model="item.IsTransfer">
                        <el-radio-button :label="true">服从</el-radio-button>
                        <el-radio-button :label="false">不服从</el-radio-button>
                      </el-radio-group>
                    </div>
                    <div v-if="userParameters.tableHeadYear&&getScoreLow(item)" class="base-item">
                      {{ getScoreLow(item).ScoreLowYear }}最低分：{{
                        getScoreLow(item).ScoreLow
                      }}分
                    </div>
                    <div v-if="userParameters.tableHeadYear&&getScoreLow(item)" class="base-item">
                      {{ getScoreLow(item).ScoreLowYear }}最低位：{{
                        getScoreLow(item).RankLow
                      }}
                    </div>
                    <div
                      v-if="userParameters.wishCategory === 0 && item.EnrollPercent"
                      class="right-box-list-item"
                    >
                      录取概率：{{ item.EnrollPercent }}%
                    </div>
                    <div v-if="userParameters.wishCategory === 1 && item.ForecastScore" class="right-box-list-item">
                      预测分：{{ item.ForecastScore }}
                    </div>
                  </div>
                  <div class="content-list-content-right">
                    <div class="right-title">
                      <div
                        class="right-title-item"
                        v-for="(titleItem, titleIndex) in specialtyTitle"
                        :key="titleIndex"
                      >
                        {{
                          titleItem === "wishCategory" ? userParameters.wishCategory === 0 ? "专业概率" : "预测分" : titleItem
                        }}
                      </div>
                      <div class="open-specialty">
                        <el-button class="fast-open" @click="fillSpecialty(item)" type="text" size="mini">速填专业</el-button>
                      </div>
                    </div>
                    <div class="right-box">
                      <div
                        v-if="
                        !(
                          item.selectedSpecialty &&
                          item.selectedSpecialty.length !== 0
                        )
                      "
                        class="have-no-specialty"
                      >
                        您还没有选择专业！
                      </div>
                      <div
                        v-else
                        class="right-box-list base-item"
                        v-for="(subItem, subIndex) in item.selectedSpecialty"
                        :key="subIndex"
                      >
                        <div class="right-box-list-item">
                          {{ subItem.EnrollmentCode }}
                        </div>
                        <div
                          v-if="userParameters.wishCategory === 0"
                          class="right-box-list-item"
                        >
                          {{ subItem.EnrollPercent }}%
                        </div>
                        <div v-else class="right-box-list-item">
                          {{ subItem.ForecastScore }}
                        </div>
                        <div class="right-box-list-item right-box-list-last-item">
                          {{ subItem.SpecialtyName }}
                          <div>
                            <el-tag size="small" v-if="subItem.LearnSubjectAssessment">学科评估：{{ subItem.LearnSubjectAssessment }}</el-tag>
                            <el-tag size="small" v-if="subItem.SpecialtyRank">专业排名：{{ subItem.SpecialtyRank }}</el-tag>
                          </div>
                        </div>
                        <div class="arrow-box">
                          <div class="delete-specialty" @click="deleteSpecialtyItem(item.selectedSpecialty, subIndex)">
                            <i class="el-icon-delete" style="color: rgba(var(--themecolor),1)"></i>
                          </div>
                          <div class="sort-specialty">
                            <div
                              class="arrow-box-item"
                              :class="{ 'not-allowed': subIndex === 0 }"
                              @click="
                            moveSpecialty(
                              'up',
                              item.selectedSpecialty,
                              subIndex
                            )
                          "
                            >
                              <i class="el-icon-arrow-up"></i>
                            </div>
                            <div
                              class="arrow-box-item"
                              :class="{
                            'not-allowed':
                              subIndex === item.selectedSpecialty.length - 1,
                          }"
                              @click="
                            moveSpecialty(
                              'down',
                              item.selectedSpecialty,
                              subIndex
                            )
                          "
                            >
                              <i class="el-icon-arrow-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-button">
              <el-alert
                v-if="specialtyNumberStatus"
                title="专业不能为空！"
                type="error"
                :closable="false"
                center
              ></el-alert>
              <div class="button-box">
                <el-button
                  class="content-button-item"
                  :disabled="specialtyNumberStatus"
                  type="primary"
                  plain
                  @click="saveWishTable()"
                >保存志愿</el-button>
<!--                <el-button @click="wishSort">快速排序</el-button>-->
              </div>
            </div>
            <div class="content-tips">
              <el-alert
                title="注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站；
  申明：智能推荐是国内唯一一家使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。"
                type="warning"
              />
            </div>
          </div>
          <div v-else class="have-no-data">暂无数据...</div>
        </el-scrollbar>
      </div>
    </div>
    <div
      :class="{ mask: !wishTableStatus }"
      @click="wishTableStatus = true"
    ></div>
  </div>
</template>

<script>
import API from '@/api/config'
import sortable from 'sortablejs'
// import { WishSave } from "@/api/common";
export default {
  name: "AdjustWish",
  data() {
    return {
      wishTableStatus: true,
      specialtyNumberStatus: true,
      loadingStatus: true,
      userParameters: {},
      mainData: [],
      wishStandardOrder: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      specialtyTitle: ["专业代码", "wishCategory", "开设专业"],
    };
  },
  props: {
    totalData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    totalData: {
      handler: function (newValue) {
        this.mainData = newValue;
        if(this.mainData.length > 0){
          this.dragList()
        }
        this.judgeNumber();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();

  },
  methods: {
    // 跳转到快速排序页面
    // wishSort () {
    //   this.$ls.set('sortWishList', this.totalData)
    //   this.$router.push('/wish-sort/index')
    //   // this.$router.push('/course/index')
    // },


    //save wish table
    saveWishTable() {
      this.$getStorage("oldWish").then((res) => {
        this.userParameters = res;
        
        const wishSaveParam = {
          Id: this.userParameters.ID || "",
          OrgId: this.userParameters.OrgId || null,
          UserId: this.userParameters.UserId || null,
          WishType: this.userParameters.wishCategory,
          Score: this.userParameters.Score || null,
          BatchId: this.userParameters.BatchId || null,
          BatchName: this.userParameters.BatchName || null,
          SubjectType: this.userParameters.SubjectType || null,
          ExamProvinceId: this.userParameters.ProvinceId || null,
          ExamProvinceName: this.userParameters.ProvinceName || null,
          RankLow: this.userParameters.Rank || null,
          ScoreEqual: this.userParameters.ScoreEqual || null,
          ScoreDiff: this.userParameters.lineDifference || null,
          PlanYear: this.userParameters.tableHeadYear.PlanYear || null,
          WishSchoolList: [],
          SelectSubjectList: []
        };
        if(!wishSaveParam.Id) delete wishSaveParam.Id
        this.mainData.forEach((item, index) => {

          let schoolScoreList = this.handleItem(item)

          wishSaveParam.WishSchoolList.push({

            // *************
            GroupCode: item.GroupCode || null,
            ExamCondition: item.ExamCondition || null,


            SchoolId: item.SchoolId || null,
            SchoolName: item.SchoolName || null,
            SchoolCode: item.SchoolCode || null,
            WishCode: this.wishStandardOrder.charAt(index),
            PlanPersons: item.PlanPersons || null,
            LearningYears: item.LearningYears || null,
            LearningCost: item.LearningCost || null,
            ScoreLowYear: item.ScoreLowYear || null,
            ScoreLow: item.ScoreLow || null,
            RankLow: item.RankLow || null,

            IsTransfer: item.IsTransfer,
            Code: "",
            EnrollmentPercent: this.userParameters.wishCategory === 1
              ? item.ForecastScore
              : item.EnrollPercent,
            WishSpecialtyList: [],

            Ranking: item.Ranking || null,
            RankingScore: item.RankingScore || null,
            RankingSort: item.RankingSort || null,
            RankingType: item.RankingType || null,

            ScoreList: schoolScoreList,

          })

          item.selectedSpecialty.forEach((subItem, subIndex) => {
            // const planData = this.getScoreLow(subItem);

            let specialtyScoreList = this.handleItem(subItem)


            wishSaveParam.WishSchoolList[index].WishSpecialtyList.push({
              SpecialtyCode: subItem.SpecialtyCode || null,
              Code: subItem.EnrollmentCode || null,

              SpecialtyName: subItem.SpecialtyName || null,
              EnrollmentPercent:
                this.userParameters.wishCategory === 1
                  ? subItem.ForecastScore
                  : subItem.EnrollPercent,

              EnrollmentSpecialty: subItem.EnrollmentSpecialty || null,

              Persons: subItem.PlanPersons || item.Persons || null,
              LearningYears: subItem.LearningYears || null,
              LearningCost: subItem.LearningCost || null,
              ScoreLowYear: subItem.ScoreLowYear || null,
              ScoreLow: subItem.ScoreLow || null,
              RankLow: subItem.RankLow || null,
              LearnSubjectAssessment: subItem.LearnSubjectAssessment || null,
              SpecialtyRank: subItem.SpecialtyRank || null,
              Sort: subIndex + 1,

              ScoreList: specialtyScoreList || null,

            });
          });
        });
        API.User.SaveWish(wishSaveParam)
          .then((res) => {

            if (res.code !== 0) {

              this.$message.error(res.message)
              return
            }

            this.$messageTips("success", "志愿保存成功！");
            setTimeout(() => {
              
              let routerUrl = this.$router.resolve({
                path: "/wish-table",
                query: {
                  WishId: res.data
                },
              });
              window.open(routerUrl.href);

              this.$ls.remove('selectedOldWish')
            }, 600);
          })
          .catch((err) => {
            this.$messageTips("error", err.message);
          });
      });
    },
    handleItem(item) {
      let data = []
      if (item.ScoreLowOfYear1) {
        data.push({
          Year: this.userParameters.tableHeadYear.Year1,
          ScoreLow: item.ScoreLowOfYear1,
          RankLow: item.RankLowOfYear1
        })
      }
      if (item.ScoreLowOfYear2) {
        data.push({
          Year: this.userParameters.tableHeadYear.Year2,
          ScoreLow: item.ScoreLowOfYear2,
          RankLow: item.RankLowOfYear2
        })
      }
      if (item.ScoreLowOfYear3) {
        data.push({
          Year: this.userParameters.tableHeadYear.Year3,
          ScoreLow: item.ScoreLowOfYear3,
          RankLow: item.RankLowOfYear3
        })
      }
      return data
    },
    // 删除指定志愿院校
    deleteWishCollege(item){
      this.totalData.some(selectedItem => {
        if(selectedItem.SchoolName === item.SchoolName){
          this.$delete(selectedItem, 'selectedSpecialty')
          this.$delete(selectedItem, 'beyondSpecialtyLimitNumber')
        }
      })
      this.totalData.$removeOfProperty('SchoolName', item.SchoolName)
    },
    // 获取最低分数
    getScoreLow(val) {
      let data = {};
      let flog = true;
      if (val.ScoreLowOfYear1 && flog) {
        data = {
          ScoreLow: val.ScoreLowOfYear1,
          RankLow: val.RankLowOfYear1,
          ScoreLowYear: this.userParameters.tableHeadYear.Year1,
        };
        flog = false;
      } else if (val.ScoreLowOfYear2 && flog) {
        data = {
          ScoreLow: val.ScoreLowOfYear2,
          RankLow: val.RankLowOfYear2,
          ScoreLowYear: this.userParameters.tableHeadYear.Year2,
        };
        flog = false;
      } else if (val.ScoreLowOfYear3 && flog) {
        data = {
          ScoreLow: val.ScoreLowOfYear3,
          RankLow: val.RankLowOfYear3,
          ScoreLowYear: this.userParameters.tableHeadYear.Year3,
        };
        flog = false;
      }
      return data;
    },
    //print wish table
    // printWishTable() {
    //   this.storageFinalData();
    // },
    // //storage final data and jump link
    // storageFinalData() {
    //   this.$setStorage("finalWishTable", this.mainData).then(() => {
    //     setTimeout(() => {
    //       this.$router.resolve("/wish-list");
    //       window.open(this.$router.resolve("/wish-list").href);
    //     }, 300);
    //   });
    // },
    //judge specialty number
    judgeNumber() {
      this.mainData.some((item) => {
        if (!item.selectedSpecialty || item.selectedSpecialty.length === 0) {
          this.specialtyNumberStatus = true;
          return true;
        } else {
          this.specialtyNumberStatus = false;
        }
      });
    },
    // 打开对应的专业列表
    fillSpecialty(item){
      this.wishTableStatus = !this.wishTableStatus; // 关闭志愿调整面板
      setTimeout(() => {
        this.$emit('openSpecialtyChoose', item)
      }, 300)
    },
    // 删除专业项
    deleteSpecialtyItem(specialtyArray, subIndex){
      this.$delete(specialtyArray, subIndex)
    },
    //move specialty order
    moveSpecialty(direction, specialtyArray, index) {
      let temp;
      if (direction === "up") {
        if (index === 0) return;
        temp = specialtyArray[index];
        this.$set(specialtyArray, index, specialtyArray[index - 1]);
        this.$set(specialtyArray, index - 1, temp);
      } else {
        if (index === specialtyArray.length - 1) return;
        temp = specialtyArray[index];
        this.$set(specialtyArray, index, specialtyArray[index + 1]);
        this.$set(specialtyArray, index + 1, temp);
      }
    },

    //open table for adjust wish
    openAdjustWish() {
      this.wishTableStatus = !this.wishTableStatus;
      if (this.wishTableStatus === false) {
        // this.wishGradeDropDownList = [];
        // this.createWishList();
        // this.sortWish();
        setTimeout(() => {
          this.loadingStatus = false;
        }, 300);
      }
    },

    //sort main list
    /*sortWish() {
      this.mainData.sort((a, b) => {
        return (
          this.wishStandardOrder.indexOf(a.wishGrade) -
          this.wishStandardOrder.indexOf(b.wishGrade)
        );
      });
    },*/
    //get user parameters
    initialize() {
      this.$getStorage("oldWish").then((res) => {
        this.userParameters = res;
      });

      this.$nextTick(() => {
        this.dragList()
      })
    },
    // 拖拽院校
    dragList(){
      let contentBox = document.querySelector('#content_box')
      if(contentBox){
        const isThis = this
        let sortConfig = {
          animation: 300,
          onStart(){},
          onEnd({newIndex, oldIndex}){ // 解构赋值
            let dragItem = isThis.totalData.splice(oldIndex,1)[0]
            isThis.totalData.splice(newIndex,0,dragItem)
          }
        }
        sortable.create(contentBox, sortConfig)
      }
    },
  },
};
</script>

<style scoped lang="less">

.adjust-wish {
  .main {
    height: 100%;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2001;
    display: flex;
    align-items: center;
    .left {
      box-sizing: border-box;
      width: 2.3rem;
      // height: 9rem;
      padding: 1.5rem 0.6rem;
      font-size: 18px;
      background-color: rgba(var(--themecolor),0.75);
      color: #fff;
      cursor: pointer;
      user-select: none;
      position: absolute;
      right: 100%;
      bottom: 50%;
    }
    .right {
      width: 55rem;
      height: 100%;
      background-color: #fff;
      transition: width 0.3s ease-out;
      .box {
        height: 100%;
        .content {
          &-list {
            padding: 0.5rem;
            &-head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 2px solid var(--color-red);

              &-first {
                display: flex;

                color: #ffffff;
                font-style: italic;
                .wish-grade{
                  margin-right: 1rem;
                  padding: 0.4rem 1.5rem;
                  background-color: var(--color-red);
                }
                .drag-tips{
                  line-height: 31px;
                  padding: 0 10px;
                  cursor: pointer;
                  background-color: #cccccc;
                }
              }
              &-second {
                .delete-button{
                  padding: 8px 15px;
                }
              }
            }
            &-content {
              padding: 0.5rem 0;
              display: flex;
              &-left {
                width: 14rem;
              }
              &-right {
                width: 39rem;
                padding-left: 1rem;
                border-left: 1px solid var(--color-grey);
                .right-title {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5rem;
                  &-item {
                    width: 4rem;
                    font-size: 14px;
                    margin-right: 1rem;
                  }
                  .open-specialty{
                    .fast-open{
                      padding: 0 10px;
                    }
                  }
                }
                .right-box {
                  &-list {
                    padding: 0.5rem;
                    display: flex;
                    color: #606266;
                    background-color: rgba(54, 134, 226, 0.08);
                    position: relative;
                    &-item {
                      width: 4rem;
                      margin-right: 1rem;
                    }
                    &-last-item {
                      width: 24rem;
                      margin-right: 0;
                    }
                    .arrow-box {
                      position: absolute;
                      right: 0;
                      top: 50%;
                      transform: translateY(-50%);
                      box-shadow: 0 0 4px var(--boxShadow);
                      display: flex;
                      align-items: center;
                      .delete-specialty{
                        line-height: 32px;
                        padding: 0 5px;
                        margin-right: 5px;
                        background-color: rgba(54, 134, 226, 0.08);
                        cursor: pointer;
                      }
                      .sort-specialty{}
                      &-item {
                        padding: 0 3px;
                        border: 1px solid transparent;
                        background-color: rgba(54, 134, 226, 0.08);

                        &:hover {
                          color: rgba(var(--themecolor),1);
                          cursor: pointer;
                        }
                      }
                      .not-allowed {
                        cursor: not-allowed;
                        color: inherit;
                      }
                    }
                  }
                }
              }
              .base-item {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                font-size: 13px;

                .school-name {
                  color: rgba(var(--themecolor),1);
                  margin-left: 0.5rem;
                  font-size: 16px;
                }
              }
            }
          }
          &-button {
            text-align: center;
            &-item {
              width: 10rem;
            }
          }
          &-tips {
            margin-top: 2rem;
          }
        }

        > .el-scrollbar__wrap {
          overflow-x: hidden;
        }
      }
    }
    .wish-table {
      width: 0;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2000;
  }
  .have-no-data {
    padding: 5rem 0;
    font-size: 16px;
    color: rgba(var(--themecolor),1);
    text-align: center;
  }
  .have-no-specialty {
    padding: 2rem 0;
    font-size: 13px;
    color: var(--color-red);
  }
}
</style>
