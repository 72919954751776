<template>
  <div v-if="authorization" class="self-first">
    <div class="head">
      <first-head-search valueKey="SchoolName" placeHolder="请输入院校名称..." @searchData="searchData"
        @handleSearchSelect="handleSearchSelect"></first-head-search>
    </div>
    <div v-if="showContent" class="main">
      <content-show ref="contentShow" chooseType="self" :contentApi="contentApi" v-bind="$attrs"
        v-on="$listeners"></content-show>
    </div>
  </div>
  <div v-else>
    <member-limit :text_1="wishCategory===0?'开通':'升级'" :text_2="wishCategory===0?nameConfig.WCF:nameConfig.WDF"></member-limit>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from 'vuex'
// import {MySelfSearch, MySelf} from '@/api/common'
// import {MySelfSearchForecast, MySelfForecast} from '@/api/WDFapi/common'
// import VipLimitCover from '@/views/wish/VIPLimitCover'
import MemberLimit from './MemberLimit.vue'
import FirstHeadSearch from './FirstHeadSearch'
import ContentShow from './ContentShow'

export default {
  name: "SelfFirst",
  components: {
    FirstHeadSearch,
    ContentShow,
    MemberLimit
  },
  data() {
    return {
      showContent: false,
      wishCategory: 0,
      contentApi: {
        list: API.Wish.MySelf
      },
      query: {},
      // hasZNK: this.$store.state.user.hasZNK,
      // hasVip: this.$store.state.user.hasVip
      authorization: true
    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'token',
      'nameConfig'
    ])
  },
  created() {
    this.initialize()
  },
  mounted() {

  },
  methods: {
    //select item
    handleSearchSelect(query) {
      this.query.SchoolLineId = query.ID
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs.contentShow) {
            this.$refs.contentShow.mergeParameters(this.query)
          }
        })
      }, 0)
      this.showContent = true
    },
    //search list
    searchData(query, callback) {
      this.query.SchoolName = query
      if (this.wishCategory === 0) {
        API.Wish.MySelfSearch(this.query).then(res => {
          callback(res.data)
        })
      } else {
        API.Wish.MySelfSearchForecast(this.query).then(res => {
          callback(res.data)
        })
      }
    },
    //initialize parameters
    initialize() {
      this.$getStorage('oldWish').then(res => {
        this.query = {
          BatchId: res.BatchId,
          ProvinceId: res.ProvinceId,
          SubjectType: res.SubjectType
        }
        this.wishCategory = res.wishCategory
        if (res.wishCategory === 0) {
          this.contentApi = {
            list: API.Wish.MySelf
          }
        } else {
          this.contentApi = {
            list: API.Wish.MySelfForecast
          }
        }
        // 权限
        let temp = (this.wishCategory === 0 ? 'WCFTB' : 'WDFTB')

        this.$store.dispatch('user/allowMember', [temp]).then(flag => {
          this.authorization = flag
        })
      })
    }
  }
}
</script>

<style scoped></style>
