<template>
  <div class="condition-screen">
    <el-collapse-transition>
      <div v-show="screenShow" class="screen-box">
        <div class="screen-list">
          <div class="screen-list-title">
            所在省份：
            <div class="tips-text">（可多选）</div>
          </div>
          <div class="screen-list-all" :class="{ 'activate': query.provinceID.length !== 0 }"
            @click="handleScreenAll('province')">不限
          </div>
          <span class="divider-vertical"></span>
          <el-checkbox-group class="screen-list-box" v-model="query.provinceID">
            <el-checkbox v-for="(item, index) in mainData.ProvinceList" :key="index" :label="item.ID">{{ item.Name }}
            </el-checkbox>
          </el-checkbox-group>

        </div>
        <div class="divider-horizontal"></div>
        <div class="screen-list">
          <div class="screen-list-title">
            院校类型：
            <div class="tips-text">（可多选）</div>
          </div>
          <div class="screen-list-all" :class="{ 'activate': query.categoryID.length !== 0 }"
            @click="handleScreenAll('category')">不限
          </div>
          <span class="divider-vertical"></span>
          <el-checkbox-group class="screen-list-box" v-model="query.categoryID">
            <el-checkbox v-for="(item, index) in mainData.SchoolCategoryList" :key="index" :label="item.ID">
              {{ item.CategoryName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="divider-horizontal"></div>
        <div class="screen-list">
          <div class="screen-list-title">
            院校性质：
            <div class="tips-text">（单选）</div>
          </div>
          <div class="screen-list-all" :class="{ 'activate': query.natureValue !== '' }"
            @click="handleScreenAll('nature')">
            不限
          </div>
          <span class="divider-vertical"></span>
          <el-radio-group class="screen-list-box" v-model="query.natureValue">
            <el-radio v-for="(item, index) in mainData.SchoolNatureList" :key="index" :label="item.Value">{{ item.Name }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="divider-horizontal"></div>
        <div class="screen-list">
          <div class="screen-list-title">
            热门标签：
            <div class="tips-text">（可多选）</div>
          </div>
          <div class="screen-list-all" :class="{ 'activate': query.tagValue.length !== 0 }"
            @click="handleScreenAll('tag')">
            不限
          </div>
          <span class="divider-vertical"></span>
          <el-checkbox-group class="screen-list-box" v-model="query.tagValue">
            <el-checkbox v-for="(item, index) in mainData.SchoolTagList" :key="index" :label="item.Value">{{ item.Name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="divider-horizontal"></div>
        <div class="screen-list">
          <div class="screen-list-title">
            分数范围：
          </div>
          <el-slider class="sliderValue" :marks="marks" @change="sliderChange" v-model="sliderValue" range
            :min="ScoreLimitMin" :max="ScoreLimitMax"> </el-slider>
        </div>
        <div class="submit-box">
          <el-button @click="submitScreenParameters" @mouseenter.native="buttonText = limitText"
            @mouseleave.native="buttonText = '确认筛选'" 
            :type="authorization ? 'primary':(buttonText === '确认筛选' ? 'primary' : 'danger')"
             plain size="mini">{{
              authorization ? '确认筛选' : buttonText }}</el-button>
        </div>
      </div>
    </el-collapse-transition>
    <div class="bottom-box">
      <el-divider>
        <el-button @click="screenShow = !screenShow" type="text" size="medium">{{ screenShow ? '收 起' : '展 开'
        }}</el-button>
      </el-divider>

    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";

export default {
  name: "ConditionScreen",
  data() {
    return {
      mainData: {},
      screenShow: false,
      buttonText: "确认筛选",
      query: {
        provinceID: [],
        categoryID: [],
        natureValue: "",
        tagValue: [],
        ScoreLowerLimit: PLATFROM_CONFIG.ScoreLowerLimit || -30,
        ScoreUpperLimit: PLATFROM_CONFIG.ScoreUpperLimit || 15,
      },
      ScoreLimitMax: PLATFROM_CONFIG.ScoreLimitMax || 80,
      ScoreLimitMin: PLATFROM_CONFIG.ScoreLimitMin || -100,
      sliderValue: [PLATFROM_CONFIG.ScoreLowerLimit || -30, PLATFROM_CONFIG.ScoreUpperLimit || 15],
      marks: {
        0: {
          style: {
            color: '#1989FA'
          },
          label: this.$createElement('strong', '0')
        }
      },

      authorization: true,

      limitText: '开通会员',
      productCode: 'WCF'

    };
  },
  computed: {
    ...mapGetters(["roles", "token", "nameConfig"]),
  },
  mounted() {
    this.initialize();
  },
  methods: {
    sliderChange(val) {
      if (val[0] > 0) {
        this.$message.warning('分数下限不能大于0');
        this.sliderValue = [PLATFROM_CONFIG.ScoreLowerLimit || -30, val[1]];
      }
      if (val[1] < 0) {
        this.$message.warning('分数上限不能小于0');
        this.sliderValue = [val[0], PLATFROM_CONFIG.ScoreUpperLimit || 15];
      }
    },
    //submit parameters
    submitScreenParameters() {

      if (this.authorization) {

        this.query.ScoreLowerLimit = this.sliderValue[0];
        this.query.ScoreUpperLimit = this.sliderValue[1];
        this.$emit("submitParameters", this.query);
      } else {
        this.$router.push('/openvip?productCode='+this.productCode)
      }
    },
    //no limit button
    handleScreenAll(item) {
      switch (item) {
        case "province":
          this.query.provinceID = [];
          break;
        case "category":
          this.query.categoryID = [];
          break;
        case "nature":
          this.query.natureValue = "";
          break;
        case "tag":
          this.query.tagValue = [];
          break;
      }
    },
    //initialize data
    initialize() {
      API.School.BaseCondition().then((res) => {
        this.mainData = res.data;
      });


      // 权限
      this.$getStorage("oldWish").then(res => {

        let temp = (res.wishCategory === 0 ? 'WCFTB' : 'WDFTB')
        this.productCode = res.wishCategory === 0 ? 'WCF':'WDF'
        this.limitText = res.wishCategory === 0 ? '开通'+this.nameConfig.WCF:'升级'+this.nameConfig.WDF

        this.$store.dispatch('user/allowMember', [temp]).then(flag => {
          this.authorization = flag
        })
      })
    },
  },
};
</script>

<style scoped lang="less">
.condition-screen {
  .screen-box {
    .screen-list {
      display: flex;

      &-title {
        width: 6rem;
        padding: 0.15rem 0;
        color: #606266;
      }

      &-all {
        box-sizing: border-box;
        height: 25.2px;
        line-height: 19px;
        padding: 0.15rem 0.8rem;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid var(--color-red);
        border-radius: 4px;
        color: var(--color-red);
        user-select: none;
      }

      .activate {
        color: var(--text-font-color);
        border: 1px solid var(--color-grey);
        border-radius: 4px;
      }

      &-box {
        width: calc(100% - 11rem);

        ::v-deep .el-checkbox {
          margin: 0;
        }

        ::v-deep .el-checkbox__input {
          display: none;
        }

        ::v-deep .el-checkbox__label {
          margin: 0 0.3rem 0.5rem 0;
          padding: 0.15rem 0.8rem;
          border: 1px solid transparent;
          border-radius: 4px;
        }

        ::v-deep .is-checked .el-checkbox__label {
          border: 1px solid var(--color-grey);
          border-radius: 4px;
          background-color: rgba(54, 134, 226, 0.08);
        }

        ::v-deep .el-radio {
          margin: 0;
        }

        ::v-deep .el-radio__input {
          display: none;
        }

        ::v-deep .el-radio__label {
          display: inline-block;
          margin: 0 0.3rem 0.5rem 0;
          padding: 0.3rem 0.8rem;
          border: 1px solid transparent;
          border-radius: 4px;
        }

        ::v-deep .is-checked .el-radio__label {
          border: 1px solid var(--color-grey);
          border-radius: 4px;
          background-color: rgba(54, 134, 226, 0.08);
        }
      }
    }

    .submit-box {
      padding: 0.5rem 0 1rem;
      text-align: center;
    }
  }

  .bottom-box {
    text-align: center;
  }

  .tips-text {
    color: var(--text-font-color);
    font-size: 13px;
  }

  .divider-horizontal {
    margin: 1.2rem 0;
    border-bottom: 1px dashed var(--color-grey);
    /*border: 1px solid red;*/
  }

  .divider-vertical {
    width: 1px;
    height: 25.2px;
    margin: 0 0.5rem;
    background-color: var(--color-grey);
  }
}

.sliderValue {
  width: 400px;
}
</style>
