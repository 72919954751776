<template>
  <div class="w-c-index">
    <div class="info-main">
      <head-user-info :totalData="totalData" @changeBatch="contentShowChange" v-on="$listeners"></head-user-info>
    </div>
    <div class="content-box" v-if="contentShow">
      <el-tabs type="border-card">
        <el-tab-pane label="院校优先填报">
          <school-first :totalData="totalData" :wishLimitNumber="wishLimitNumber"
            @showSpecialty="showSpecialty"></school-first>
        </el-tab-pane>
        <el-tab-pane label="专业优先填报">
          <specialty-first :totalData="totalData" :wishLimitNumber="wishLimitNumber"
            @showSpecialty="showSpecialty"></specialty-first>
        </el-tab-pane>
        <el-tab-pane label="自主填报">
          <self-first :totalData="totalData" :wishLimitNumber="wishLimitNumber"
            @showSpecialty="showSpecialty"></self-first>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 调整志愿面板 开始 -->
    <adjust-wish @openSpecialtyChoose="showSpecialty" v-on="$listeners" :totalData="totalData"></adjust-wish>
    <!-- 调整志愿面板 结束 -->

    <!-- 专业展示 开始 -->
    <specialty-list ref="specialtyList" :specialtyFillStatus="specialtyFillStatus"
      :specialtyLimitNumber="wishLimitNumber.SpecialtyCount" @specialtyData="specialtyData"></specialty-list>
    <!-- 专业展示 结束 -->

  </div>
</template>

<script>
import API from '@/api/config'
// import { ProvinceWishCount } from "@/api/common";
import HeadUserInfo from './HeadUserInfo'
import SchoolFirst from './SchoolFirst'
import SpecialtyFirst from './SpecialtyFirst'
import SelfFirst from './SelfFirst'
import AdjustWish from './AdjustWish'
import SpecialtyList from "./SpecialtyList";

export default {
  name: "WCIndex",
  components: {
    SchoolFirst,
    HeadUserInfo,
    SpecialtyFirst,
    SelfFirst,
    AdjustWish,
    SpecialtyList,
  },
  data() {
    return {
      contentShow: true,
      totalData: [],
      schoolMainData: [],
      specialtyMainData: [],
      selfMainData: [],

      specialtyFillStatus: false,
      wishLimitNumber: {}
    }
  },
  props: {
    editorTotalData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    totalData: {
      handler: function (newValue) {
        // this.everyListWishGrade(newValue)
      },
      deep: true
    }
  },
  mounted() {
    // this.totalData = this.editorTotalData
    this.initialize()
  },
  
  methods: {
    contentShowChange() {
      this.totalData = []
      this.contentShow = false;
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$getStorage("oldWish").then(res => {
        // 院校和专业的志愿数量限制
        API.Public.ProvinceWishCount({
          ProvinceId: res.ProvinceId,
          EducationLevel: res.EducationLevel,
          BatchId: res.BatchId
        }).then(wishLimitNumber => {
          this.wishLimitNumber = wishLimitNumber.data || {}
          this.contentShow = true;
          loading.close()
        })
      });
    },

    //click specialty button to show specialty list
    showSpecialty(row) {
      //judge specialty button status
      let selectedCollege
      this.specialtyFillStatus = false;
      const temp = this.totalData.every((item) => {
        if (item.ID === row.ID) {
          this.specialtyFillStatus = true;
          selectedCollege = item
          return false;
        } else {
          return true;
        }
      });
      if (temp) selectedCollege = row
      this.$refs.specialtyList.getSpecialtyListData(selectedCollege);
    },

    //receive specialty data then have selected
    specialtyData(selectedSpecialty, schoolID) {
      this.totalData.forEach((item) => {
        if (item.ID === schoolID) {
          this.$set(item, "selectedSpecialty", selectedSpecialty);
        }
      });
    },

    // initialize data
    initialize() {
      this.totalData = this.editorTotalData // 外层传入传参

      let temp = this.$ls.get('selectedOldWish')

      if(temp && temp.length > 0) {
        this.totalData = this.$ls.get('selectedOldWish')
      }

      this.$getStorage("oldWish").then(res => {
        // 院校和专业的志愿数量限制
        API.Public.ProvinceWishCount({
          ProvinceId: res.ProvinceId,
          EducationLevel: res.EducationLevel,
          BatchId: res.BatchId
        }).then(wishLimitNumber => {
          this.wishLimitNumber = wishLimitNumber.data || {}
        })
      });
    },
  }
}
</script>

<style scoped lang="less">
.w-c-index {
  .info-main {
    padding: 20px 0 20px 20px;
  }
}
</style>
