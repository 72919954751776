<template>
  <div v-if="authorization" class="specialty-first">
    <div class="head">
      <first-head-search valueKey="Name" tagValue="EducationLevel" placeHolder="请输入专业名称..." @searchData="searchData"
        @handleSearchSelect="handleSearchSelect">
      </first-head-search>
    </div>
    <div v-if="showContent" class="main">
      <div class="main-head">
        <condition-screen @submitParameters="submitParameters"></condition-screen>
      </div>
      <div class="main-content">
        <content-show ref="contentShow" chooseType="specialty" :contentApi="contentApi" v-bind="$attrs"
          v-on="$listeners"></content-show>
      </div>
    </div>
  </div>
  <div v-else>
    <member-limit :text_1="wishCategory===0?'开通':'升级'" :text_2="wishCategory===0?nameConfig.WCF:nameConfig.WDF"></member-limit>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from 'vuex'

import MemberLimit from './MemberLimit.vue'
import FirstHeadSearch from './FirstHeadSearch'
import ConditionScreen from './ConditionScreen'
import ContentShow from './ContentShow'

export default {
  name: "SpecialtyFirst",
  components: {
    // VipLimitCover,
    FirstHeadSearch,
    ConditionScreen,
    ContentShow,
    MemberLimit
  },
  data() {
    return {
      showContent: false,
      wishCategory: 0,
      contentApi: {
        statistics: API.Wish.SpecialtyFirstStat,
        list: API.Wish.SpecialtyFirstList
      },
      query: {},
      // hasZNK: this.$store.state.user.hasZNK,
      // hasVip: this.$store.state.user.hasVip,

      authorization: true,

    }
  },
  computed: {
    ...mapGetters([
      'roles',
      'token',
      'nameConfig'
    ])
  },
  created() {
    this.initialize()
  },
  mounted() {

  },
  methods: {
    //select item
    handleSearchSelect(query) {
      this.showContent = true
      this.query = {
        Type: query.Type,
        SpecialtyOrCategoryId: query.ID
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs.contentShow) {
            this.$refs.contentShow.mergeParameters(this.query)
          }
        })
      }, 0)
    },

    //search result
    searchData(searchQuery, callback) {
      API.Specialty.SpecialtyOrCategorySearch({
        Name: searchQuery
      }).then(res => {
        if (res.code === 0) {
          callback(res.data)
        }
      })
    },
    //submit parameters
    submitParameters(query) {
      this.$refs.contentShow.getListByScreen(query)
    },
    //initialize data
    initialize() {
      this.judgeWishCategory()
    },
    //get user parameters and judge wish category
    judgeWishCategory() {
      this.$getStorage('oldWish').then(res => {

        this.wishCategory = res.wishCategory

        if (res.wishCategory === 0) {
          this.contentApi = {
            statistics: API.Wish.SpecialtyFirstStat,
            list: API.Wish.SpecialtyFirstList
          }
        } else {
          this.contentApi = {
            statistics: API.Wish.SpecialtyFirstStatForecast,
            list: API.Wish.SpecialtyFirstListForecast
          }
        }
        
        // 权限
        let temp = (this.wishCategory === 0 ? 'WCFTB' : 'WDFTB')

        this.$store.dispatch('user/allowMember', [temp]).then(flag => {
          this.authorization = flag
        })


      })
    }
  }
}
</script>

<style scoped></style>
